<template>
    <v-dialog v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" color="cyan lighten-2" class="white--text my-auto" width="500"
                height="112">
                <v-card-title class="py-auto px-8 my-auto">
                    Escreva a sua sugestão
                </v-card-title>
            </v-card>
        </template>
        <v-card>
            <v-card-title>
                Faça a sua sugestão
                <v-spacer></v-spacer>
                <v-btn outlined icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col :xs="12" :sm="12" :md="3" :lg="3" :xl="3" cols="12">
                        Usuário:
                        <v-chip class="ma-2" color="blue" label outlined>
                            {{ usuario.usuario_mv }}
                        </v-chip>
                    </v-col>
                    <!-- <v-col :xs="12" :sm="12" :md="9" :lg="9" :xl="9" cols="12">
                        Setor:

                        <v-chip class="ma-2" color="green" label outlined v-for="grupo in permissoes.papel"
                            :key="grupo.id + 'a'"> {{ grupo.text }}
                        </v-chip>

                    </v-col> -->
                </v-row>
                <v-row dense>
                    <v-col>
                        <v-form ref="form">
                            <v-stepper v-model="e1">
                                <v-stepper-header>
                                    <v-stepper-step 
                                        v-for="(v,k) in campos"  :key="`${k}c`" 
                                        color="blue" :complete="e1 > v.ordem" :step="v.ordem">
                                        <v-divider v-if="v.id !== campos" :key="v.id"></v-divider>
                                    </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content v-for="(v,k) in campos" :key="`${k}b`" :step="v.ordem">
                                        <v-card class="mb-12" height="100%" flat>
                                            <v-card-text v-show="v.show">
                                                <v-textarea v-if="v.tipo.text == 'textarea'" :label="v.label"
                                                    v-model="v.resposta" outlined></v-textarea>
                                                <v-radio-group v-if="v.tipo.text == 'radio'" v-model="v.resposta"
                                                    return-object :label="v.label">
                                                    <v-radio v-for="n in v.data" :key="`${n.id}t`" :label="n.text"
                                                        :value="n"></v-radio>
                                                </v-radio-group>
                                                <div v-if="v.tipo.text == 'checkbox'">
                                                    <v-checkbox v-for="c in v.opcoes" :key="c.id + 'c'"
                                                        v-model="v.resposta" :label="c.label" color="red"
                                                        :value="c.label" hide-details></v-checkbox>
                                                </div>
                                                <div v-if="v.tipo.text == 'text-field'">
                                                    <v-text-field v-model="v.resposta" :label="v.label">
                                                    </v-text-field>
                                                </div>
                                                <div v-if="v.tipo.text == 'date'">
                                                    <v-text-field v-model="v.resposta" :label="v.label" type="date">
                                                    </v-text-field>
                                                </div>
                                                <div v-if="v.tipo.text == 'time'">
                                                    <v-text-field :label="v.label" v-model="v.resposta" type="time">
                                                    </v-text-field>
                                                </div>
                                                <v-row v-if="v.tipo.text == 'datetime'" justify="center">
                                                    <v-col cols="4">
                                                        <v-text-field :label="v.label" type="date">
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-text-field :label="v.label" type="time">
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-text v-show="v.tipo.text == 'btn'">
                                                <v-sheet height="180">
                                                    <v-btn outlined color="blue" block @click.stop="enviar()"
                                                        :disabled="show" class="pa-6 ma-2"
                                                        style="background-color:#cae5f3">
                                                        Enviar para análise&nbsp;&nbsp;&nbsp;
                                                        <v-icon right>mdi-send</v-icon>
                                                    </v-btn>
                                                </v-sheet>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn color="secondary" :disabled="volta" @click="decrement" outlined>
                                                    Voltar
                                                </v-btn>
                                                <v-btn color="secondary" @click="increment" :disabled="avanca" outlined>
                                                    Continue
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>

                                    </v-stepper-content>

                                </v-stepper-items>
                            </v-stepper>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="loading" :color="cor">
            {{ mensagem }}
            <template v-slot:action="{ attrs }">
                <v-btn color="gren" text v-bind="attrs" @click="loadinFalse">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'AddSugestao',
    props: {
        permissoes: { type: Object }
    },
    data: () => ({
        dialog: false,
        conf: { conf: { status_inicial_sugestao: '' } },
        usuario: {},
        rulesTexto: [
            v => !!v || 'O texto é obrigatório',
        ],
    }),
    computed: {
        ...mapState(['loading', 'e1']),
        ...mapGetters(['mensagem', 'cor', 'campos', 'lista_1_array']),
        avanca() {
            // se e1 for igual ao numero de objetos no array, ele desabilita o btn avancar
            return this.e1 == this.campos.length ? true : false
        },
        volta() {
            // se e1 for maior que valor 1 habilita o btn voltar
            return this.e1 > 1 ? false : true
        },
        show() {
            // se e1 for igual ao numero de objetos no array, ele habiliata o btn enviar para análise
            return this.e1 == this.campos.length ? false : true
        }
    },
    methods: {
        regra(val) {
            return val ? ['Obrigatório'] : false
        },
        ...mapActions(['increment', 'decrement']),
        loadinFalse() {
            this.$store.dispatch('btnLoading', false)
        },
        enviar() {
            if (this.$refs.form.validate()) {
                // this.$store.dispatch('btnLoading', false)
                let array = this.campos
                let key = 'field'
                const convertArrayToObject = (array, key) => {
                    const initialValue = {};
                    return array.reduce((obj, item) => {
                        return {
                            ...obj,
                            
                            [item[key]]: item.resposta,
                        };
                    }, initialValue);
                };
                let s = convertArrayToObject(array, key)
                // s.resultado = { id: this.conf.conf.status_inicial_sugestao }
                s.resultado = { id: 7 }
                var data = JSON.stringify(s);
                //data.resultado = 
                var config = {
                    method: 'post',
                    url: 'informe/sugestoes/',
                    data: data,
                    instancia: 'lista_1_array'
                };
                /** Refatorar o map e atualização refresh dos */
                this.$store.dispatch('lista', config)
                this.$store.dispatch('reset')
                this.campos.map(e => e.resposta = '')
                // this.atualizar()
                // this.dialog = false
            }
        },
        atualizar() {
            this.$store.dispatch('lista', {
                url: 'informe/sugestoes/?grupo=grupo'
            })
        },
        close() {
            this.atualizar()
            this.dialog = false
        }
    },
    mounted() {
        const token_usuario = JSON.parse(localStorage.getItem('token_usuario'))
        this.conf = JSON.parse(localStorage.getItem('cfgu'))
        this.usuario = token_usuario
        this.$store.dispatch('lista', {
            method: 'get',
            url: 'informe/campos/?app=SUGESTAO',
            instancia: 'campos'
        })

    }
}
</script>